.cmp-btn{
    position: fixed;
    bottom: 15px;
    left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    flex: 0 0 46px;
    border-radius: 16px;
    font-weight: 500;
    color: #f2f1f3;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #8036e0;
    transition: all 0.3s ease-in-out;
    z-index: 10;
    display: none;
}

/* ---
Flash Message Popup Start
--- */

.flash-message-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1100;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 10px 10px 50px;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(5px);
    background: #0000004d;
    transition: all 0.3s ease-in-out;
}

.flash-message-popup.fmp-active {
    opacity: 1;
    visibility: visible;
}

.flash-message {
    border-radius: 10px;
    padding: 20px 15px;
    max-width: 450px;
    width: 95%;
    position: relative;
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 20px);
    opacity: 0;
    visibility: hidden;
    z-index: 1101;
    background-color: #111827;
    box-shadow: rgba(0, 0, 0, 0.01) 0px 207px 58px, rgba(0, 0, 0, 0.08) 0px 132px 53px, rgba(0, 0, 0, 0.27) 0px 74px 45px, rgba(0, 0, 0, 0.45) 0px 33px 33px, rgba(0, 0, 0, 0.52) 0px 8px 18px, rgba(0, 0, 0, 0.53) 0px 0px 0px, rgba(255, 255, 255, 0.08) 0px 1px 1px inset, rgba(0, 0, 0, 0.25) 0px 0px 0px 1px inset;
    transition: all 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.flash-message-inner {
    display: flex;
    align-items: center;
}

.flash-message.fmp-message {
    transform: translate(-50%, 0px);
    opacity: 1;
    visibility: visible;
}

.flash-message .flash-img {
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    margin-right: 10px;
    align-self: self-start;
}

.flash-message.ef-msg .flash-text h6 {
    font-weight: 500;
    font-size: 20px;
    color: #c70000;
    margin-bottom: 0px;
}

.flash-message.sf-msg .flash-text h6 {
    font-weight: 500;
    font-size: 20px;
    color: #28a745;
    margin-bottom: 0px;
}

.flash-message .flash-text p {
    color: white;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
    margin: 0;
    margin-top: 5px;
}

.flash-message .msg-close {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333333;
    font-size: 18px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    overflow: hidden;
}

.flash-message .msg-close:hover {
    color: #c70000;
    transform: rotate(90deg);
}

.flash-message .msg-close svg {
    width: 100%;
    height: 100%;
}